
import * as aroraProviderRuntime$L9QSivlQIXBHHXPTKyySUhIA2_45sbaSHWDUUHUs1KXio from 'D:/deploy/UploadHidden/20250320-P903.298/arora-front/satellite/build-rest-18f614da-bf7e-44f8-8c95-ef8990a7302b/providers/aroraProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "aroraProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aroraProvider']: { provider: aroraProviderRuntime$L9QSivlQIXBHHXPTKyySUhIA2_45sbaSHWDUUHUs1KXio, defaults: {} }
}
        